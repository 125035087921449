
import { createRouter, createWebHistory } from 'vue-router';

// import HomePage from './components/HomePage.vue';
// import AboutPage from './components/AboutPage.vue';

const HomePage = () => import('./components/HomePage.vue')
const AboutPage = () => import('./components/AboutPage.vue')
const PwaPage = () => import('./components/PwaPage.vue')

const routes = [
    {
        path: '/',
        component: HomePage
    },
    { path: '/invite', component: AboutPage },
    {
        path: '/open-app',
        component: PwaPage
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;